<template>
  <div>
    <b-table-simple class="table" responsive borderless fixed>
      <thead>
        <tr>
          <th v-can="'FiConPag3'">
            <Check @change="selectAllChecks" 
              v-if="installments.some(item => ['expired', 'pending'].includes(item.status))"
            />
          </th>
          <th><Ellipsis>Situação</Ellipsis></th>
          <th><Ellipsis>Receb/Pag.</Ellipsis></th>
          <th><Ellipsis>Data de venc.</Ellipsis></th>
          <th><Ellipsis>Responsável</Ellipsis></th>
          <th><Ellipsis>Paciente/Favorecido</Ellipsis></th>
          <th><Ellipsis>Conta banc.</Ellipsis></th>
          <th><Ellipsis>Método de receb/pag.</Ellipsis></th>
          <th><Ellipsis>Forma de receb/pag.</Ellipsis></th>
          <th class="td-header">Taxa</th>
          <th><Ellipsis>Parcela</Ellipsis></th>
          <th><Ellipsis>Valor bruto</Ellipsis></th>
          <th><Ellipsis>Valor líquido</Ellipsis></th>
          <th><Ellipsis>Conferido</Ellipsis></th>
          <th class="no-print">Conta</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="installment in installments" :key="installment.id">
          <td v-can="'FiConPag3'" class="td-body checkbox-td">
            <div class="to-print type-print">
              {{
                installment?.payment?.bill?.type === 'bill_to_receive'
                  ? 'À receber'
                  : 'À pagar'
              }}
            </div>
            <Check
              v-if="['expired', 'pending'].includes(installment.status)"
              :value="!!selected?.find(el => el.id === installment.id)"
              @change="checked => selectCheck(checked, installment)"
            />
            <div class="no-print">
              <Plus
                v-if="installment?.payment?.bill?.type === 'bill_to_receive'"
                class="icon plus"
              />
              <Minus
                v-else-if="installment?.payment?.bill?.type === 'bill_to_pay'"
                class="icon minus"
              />
            </div>
          </td>

          <td class="td-body payment-status">
            <v-bill-status :status="installment.status" />
          </td>

          <td class="td-body payment-status-date">
            <span
              v-if="
                installment.status === 'paid_out' && installment?.payment_date
              "
            >
              <Ellipsis>{{ parseDate(installment.payment_date) }}</Ellipsis>
            </span>
            <span v-else>
              <p class="text-align-center">{{ '-' }}</p>
            </span>
          </td>

          <td class="td-body payment-due-date">
            <span v-if="installment?.due_date">
              <v-date-with-alert
                :id="`due_date_${installment.id}`"
                :date="installment.due_date"
                :warning="
                  installment.status === 'pending' &&
                  moment(installment.due_date).diff(moment(), 'days') <= 10
                "
                :danger="installment.status === 'expired'"
              />
            </span>
            <span v-else>
              <p class="text-align-center">{{ '-' }}</p>
            </span>
          </td>

          <td class="td-body bill-creator">
            <Ellipsis>
              {{ installment?.payment?.bill?.creator?.name || '-' }}
            </Ellipsis>
          </td>

          <td class="td-body payer-recipent">
            <span v-if="installment?.payment.bill?.type === 'bill_to_pay'">
              <Ellipsis>
                {{ installment?.payment?.bill?.beneficiary?.name || '-' }}
              </Ellipsis>
            </span>
            <span v-else>
              <Ellipsis>
                {{ installment?.payment?.bill?.patient?.name || '-' }}
              </Ellipsis>
            </span>
          </td>

          <td class="td-body payment-acc-name">
            <Ellipsis>
              {{ installment?.payment?.bank_account?.name || '-' }}
            </Ellipsis>
          </td>

          <td class="td-body payment-method">
            <Ellipsis>
              {{ installment?.payment?.payment_method?.name }}
              <span class="method-type"
                >({{
                  parseTypeName(installment?.payment?.payment_method?.type)
                }})</span
              >
            </Ellipsis>
          </td>

          <td class="td-body payment-installment-method">
            <span v-if="installment.installment_count < 2">
              <Ellipsis>À vista</Ellipsis>
            </span>
            <span v-else>
              <Ellipsis>{{
                `Parcel. em ${installment.installment_count} vezes`
              }}</Ellipsis>
            </span>
          </td>

          <td class="td-body payment-tax">
            <Ellipsis>{{
              installment?.tax ? `${installment?.tax}%` : '-'
            }}</Ellipsis>
          </td>

          <td class="td-body payment-installment">
            <span
              v-if="installment.installment_count > 2"
              class="installments blue-text"
            >
              <Ellipsis
                >Parc.
                {{
                  ` ${
                    installment.payment_type != 'bank_slip' ||
                    installment.payment_type != 'check'
                      ? installment.position
                      : installment.position + 1
                  }/${installment.installment_count}`
                }}</Ellipsis
              >
            </span>
            <span v-else>-</span>
          </td>

          <td class="td-body payment-gross-value">
            <Ellipsis
              >R${{
                parseNumberToMoney(installment.installment_value).trim().split('$')[1]
              }}</Ellipsis
            >
          </td>
          
          <td class="td-body payment-value">
            <Ellipsis
              >R${{
                parseNumberToMoney(installment.amount).trim().split('$')[1]
              }}</Ellipsis
            >
          </td>

          <td>
            <label class="ml-2">
              <Check
                v-model="installment.conferred"
                :value="installment.conferred"
                @change="onChangeConfered(installment)"
              />
            </label>
          </td>

          <td class="td-body no-print">
            <Eye class="eye-icon" @click="onEdit(installment)" />
          </td>
        </tr>
      </tbody>
    </b-table-simple>

    <v-no-filter-results
      v-if="!installments?.length"
      :message="'Os filtros aplicados não geraram nenhum resultado.'"
    />

    <v-documents-modal :bill.sync="bill" @onHide="getInstallments" />
  </div>
</template>

<script>
import { parseNumberToMoney } from '@/utils/moneyHelper'
import { parseTypeName } from '@/modules/financial/utils/financialHelper'

export default {
  props: {
    installments: { type: Array, required: true },
    onEdit: Function,
    getInstallments: Function
  },
  components: {
    Check: () => import('@/components/General/Check'),
    Plus: () => import('@/assets/icons/plus.svg'),
    Minus: () => import('@/assets/icons/minus-rounded2.svg'),
    Eye: () => import('@/assets/icons/eye.svg'),
    'v-documents-modal': () =>
      import('@/modules/financial/modals/BillsToPayDocumentsModal'),
    'v-bill-status': () => import('@/modules/financial/components/BillStatus'),
    'v-date-with-alert': () => import('@/components/General/DateWithAlert'),
    'v-no-filter-results': () => import('@/components/General/noFilterResults'),
    Ellipsis: () => import('@/components/General/Ellipsis')
  },
  data() {
    return {
      selected: [],
      showButton: false,
      bill: null,
      todayTimestampDate: this.getTodayTimestampDate()
    }
  },
  methods: {
    parseTypeName,
    getPaymentMethodNumber(installment) {
      const billInstallments = this.installments.filter(
        i => i.payment?.bill_id === installment.payment?.bill_id
      )

      const groupedPayments = billInstallments.reduce((acc, curr) => {
        const groupedInstallment = acc.find(
          i => i?.payment?.id === curr.payment?.id
        )
        if (!groupedInstallment) return acc.concat([curr])
        else return acc
      }, [])

      return groupedPayments.findIndex(i => i.id === installment.id) + 1 || 1
    },
    parseDate(date) {
      return this.moment(date).format('DD/MM/YYYY')
    },
    getPendingValueCondition(dueDate) {
      return (
        this.getTodayTimestampDate() >
        parseInt(Date.parse(dueDate) + 86400000 - 864000000, 10)
      )
    },
    getOverdueTimestampDate(dueDate) {
      return parseInt(Date.parse(dueDate) + 86400000, 10)
    },
    getTodayTimestampDate() {
      const options = { year: 'numeric', month: 'numeric', day: 'numeric' }
      return Date.parse(new Date().toLocaleDateString('en-US', options))
    },
    parseNumberToMoney,
    selectAllChecks(checked) {
      this.selected = []
      if (checked) {
        this.showButton = true
        this.installments.forEach(installment => {
          if (['expired', 'pending'].includes(installment.status)) {
            this.selected.push(installment)
          }
        })
      } else {
        this.showButton = false
      }
    },
    async onChangeConfered(installment) {
      const isLoading = this.$loading.show()
      try {
        await this.api.updateInstallmentConferred(installment.id, {
          conferred: installment.conferred
        })
        if (installment.conferred) {
          this.$toast.success('Transação conferida com sucesso!')
        } else {
          this.$toast.success('Transação desmarcada com sucesso!')
        }
        await this.getInstallments()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    selectCheck(checked, billsToPaySelected) {
      if (!checked) {
        const selected = [...this.selected]
        const index = selected
          .map(item => item.id)
          .indexOf(billsToPaySelected.id)
        selected.splice(index, 1)
        this.selected = selected
        return
      }
      this.selected.push(billsToPaySelected)
    },
    opendModalDocuments(bill) {
      this.bill = Object.assign({}, bill)
      this.$bvModal.show('bills-to-pay-documents-modal')
    }
  },
  watch: {
    selected: function (value) {
      const billsToPay = value.filter(
        v => v?.payment?.bill?.type === 'bill_to_pay'
      )
      const billsToReceive = value.filter(
        v => v?.payment?.bill?.type === 'bill_to_receive'
      )
      this.$emit('selected-bills', billsToPay ?? [], billsToReceive ?? [])
      if (!value.length) {
        this.showButton = false
      }
    },
    installments() {
      this.showButton = false
      this.selected = []
      this.$emit('selected-bills', [])
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/table.scss';

th,
td {
  border: none !important;
  vertical-align: middle !important;
  .blue-text {
    font-weight: 700;
    color: var(--blue-500);
  }
}
.checkbox-td {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.checkbox {
  margin: 0 0 25px;
}
.installments {
  font-family: 'Nunito Sans';
  font-style: normal;
  margin-right: 3px;
}
.options {
  cursor: pointer;
  width: 24px;
  height: 28px;
  position: relative;
  display: inline-block;

  .more-icon {
    width: 24px;
    height: 24px;
    stroke: var(--neutral-500);
    transition: all 0.5s;
    cursor: pointer;
  }

  .menu {
    width: 180px;
    position: absolute;
    top: 28px;
    right: 0;
    background: var(--neutral-000);
    border: 1px solid var(--neutral-200);
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1),
      0px 4px 10px -8px rgba(12, 29, 89, 0.2);
    border-radius: 8px;
    padding: 0;
    opacity: 0;
    transition: all 0.5s;
    flex-direction: column;
    align-items: start;
    display: none;

    .mtb-12 {
      margin: 12px 0;
    }

    .btn {
      font-weight: 600;
      width: 100%;

      &.remove {
        color: var(--states-error);
      }

      &.btn-link {
        text-align: left;
        padding: 12px 18px !important;
      }
    }
  }

  &:hover {
    .more-icon {
      transform: rotate(90deg);
    }

    .menu {
      opacity: 1;
      display: flex;
      z-index: 10;
    }
  }
}
.times-tag {
  padding: 8px 16px;
  border-radius: 50px;
  background-color: var(--neutral-200);
  width: fit-content;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
  align-items: center;
  color: var(--type-active);

  &.expired {
    background: var(--light-orange-200);
  }

  &.paid {
    background: var(--states-success-soft-green);
  }

  &.pending {
    background: var(--light-orange-200);
  }
}
.help-icon {
  font-weight: 600 !important;
  width: 13% !important;
  &.expired {
    color: var(--states-error);
    .expired-icon {
      fill: #fba59d;
    }
  }
  &.pending {
    color: var(--light-orange-500);
    .pending-icon {
      fill: var(--light-orange-500);
    }
  }
  svg {
    height: 18px;
    width: 18px;
    vertical-align: sub;
    margin-left: 8px;
  }
}
.name {
  font-weight: 600;
}
.more {
  svg {
    stroke: var(--neutral-500);
  }
}
.dropdown-body {
  display: flex;
  flex-direction: column;
  .edit-text {
    font-size: 16px;
    font-weight: 600;
    color: var(--blue-500);
  }
  .delete-text {
    font-size: 16px;
    font-weight: 600;
    color: var(--states-error);
  }
}
.inline-content {
  display: flex;
  flex-direction: row;
}
.badge {
  margin-left: 8px;
}
.more-with-menu {
  width: 24px;
  height: 28px;
  position: relative;
  display: inline-block;

  .more-icon {
    width: 24px;
    height: 24px;
    stroke: var(--neutral-500);
    transition: all 0.5s;
    cursor: pointer;
  }

  .menu {
    width: 233px;
    position: absolute;
    top: 28px;
    right: 0;
    background: var(--neutral-000);
    border: 1px solid var(--neutral-200);
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1),
      0px 4px 10px -8px rgba(12, 29, 89, 0.2);
    border-radius: 8px;
    padding: 24px;
    opacity: 0;
    transition: all 0.5s;
    flex-direction: column;
    align-items: start;
    display: none;

    .mtb-12 {
      margin: 12px 0;
    }

    .btn {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 16px;

      &.remove {
        color: var(--states-error);
      }
    }
  }

  &:hover {
    .more-icon {
      transform: rotate(90deg);
    }

    .menu {
      opacity: 1;
      display: flex;
      z-index: 10;
    }
  }
}
.to-print {
  display: none;
}

#expired-warning {
  outline: none;
  cursor: pointer;
}

#pending-warning {
  outline: none;
  cursor: pointer;
}

.icon {
  width: 25px;
}

.plus {
  fill: var(--states-success);
}

.minus {
  fill: var(--states-error);
}

.hide {
  visibility: hidden;
}

.eye-icon {
  fill: var(--blue-500);
  cursor: pointer;
  width: 26px;
  height: 26px;
  margin-left: 5px;
}

.method-type {
  font-size: 11px;
  color: var(--type-placeholder);
}
</style>
